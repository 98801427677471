// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
// @import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Atlpay-primary: mat.define-palette(mat.$indigo-palette);
$Atlpay-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Atlpay-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Atlpay-theme: mat.define-light-theme((
  color: (
    primary: $Atlpay-primary,
    accent: $Atlpay-accent,
    warn: $Atlpay-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Atlpay-theme);
// @import '~bootstrap/dist/css/bootstrap.css';
@import 'bootstrap/dist/css/bootstrap.css';
@import '../node_modules/@aws-amplify/ui-angular/theme.css';

$theme-color_1: #6e90f6;
.modal-holder {
    animation-name: example;
    animation-duration: 0.3s;
  }

  @keyframes example {
    0% {
      transform: scale(0.5)
    }

    100% {
      transform: scale(1)
    }
  }

  .carousel-item {
    transition: opacity 0.7s ease !important;
    position: absolute !important;
    display: block !important;
    opacity: 0;
  }

  .carousel-item.active {
    position: relative !important;
    opacity: 1;
  }

  ngx-dropzone{
    border:2px dashed var(--bs-border-color) !important;
  }

  ngx-dropzone-preview {
    max-width: none !important;
    justify-content: normal !important;
    height: 80px !important;
    min-height: 80px !important;
    background-image: none !important;
    img {
      opacity: 1 !important;
    }

    ngx-dropzone-label {
      position: unset !important;
    }
  }

.nav-menu {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  >ul {
    display: flex;
    >li {
      position: relative;
      white-space: nowrap;
      padding: 10px 0 10px 28px;
      >a {
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: -5px;
          left: 0;
          background-color: $theme-color_1;
          visibility: hidden;
          width: 0px;
          transition: all 0.3s ease-in-out 0s;
        }
      }
    }
  }
  a {
    display: block;
    position: relative;
    color: #222222;
    transition: 0.3s;
    font-size: 15px;
    font-weight: 400;
    padding: 0 3px;
    font-family: 'Rubik', sans-serif;
    &:hover {
      &:before {
        visibility: visible;
        width: 100%;
      }
      color: $theme-color_1;
    }
  }
  li {
    &:hover {
      >a {
        &:before {
          visibility: visible;
          width: 100%;
        }
        color: $theme-color_1;
      }
    }
  }
  .active {
    >a {
      &:before {
        visibility: visible;
        width: 100%;
      }
      color: $theme-color_1;
    }
  }
  .drop-down {
    ul {
      display: block;
      position: absolute;
      left: 26px;
      top: calc(100% + 30px);
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      padding: 10px 0;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      transition: 0.3s;
      a {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        color: #062b5b;
        &:hover {
          color: $theme-color_1;
        }
      }
      .active {
        >a {
          color: $theme-color_1;
        }
      }
      li {
        &:hover {
          >a {
            color: $theme-color_1;
          }
        }
      }
    }
    &:hover {
      >ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
      }
    }
    li {
      min-width: 180px;
      position: relative;
    }
    >a {
      &:after {
        content: "\ea99";
        font-family: IcoFont;
        padding-left: 5px;
      }
    }
    .drop-down {
      ul {
        top: 0;
        left: calc(100% - 30px);
      }
      &:hover {
        >ul {
          opacity: 1;
          top: 0;
          left: 100%;
        }
      }
      >a {
        padding-right: 35px;
        &:after {
          content: "\eaa0";
          font-family: IcoFont;
          position: absolute;
          right: 15px;
        }
      }
    }
  }
}
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
  i {
    color: #222222;
  }
}
.mobile-nav {
  position: fixed;
  top: 40px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transform: translate(100%);
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  padding: 10px 0;
  width: 250px;
  height: 250px;
  * {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  a {
    display: block;
    position: relative;
    color: #222222;
    padding: 15px 20px 15px 0px;
    font-weight: 500;
    outline: none;
    border-bottom: 1px solid #f1f1f1;
    &:hover {
      color: $theme-color_1;
      text-decoration: none;
    }
  }
  .active {
    >a {
      color: $theme-color_1;
      text-decoration: none;
    }
  }
  li {
    &:hover {
      >a {
        color: $theme-color_1;
        text-decoration: none;
      }
    }

  }
  .drop-down {
    >a {
      &:after {
        content: "\ea99";
        font-family: IcoFont;
        padding-left: 10px;
        position: absolute;
        right: 15px;
      }
      padding-right: 35px;
    }
    ul {
      display: none;
      overflow: hidden;
    }
    li {
      padding-left: 20px;
    }
  }
  .active.drop-down {
    >a {
      &:after {
        content: "\eaa1";
      }
    }
  }
}
.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(9, 9, 9, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}
.mobile-nav-active {
  overflow: hidden;
  .mobile-nav {
    opacity: 1;
    visibility: visible;
    transform: translate(0%);
  }
  .mobile-nav-toggle {
    i {
      color: #fff;
    }
  }
}
.otp_input{
  .wrapper {
    height: 40px !important;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 170px!important;
}
html, body { height: 100%; scroll-behavior: smooth; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.theme-green {
	.bs-datepicker-body {
		table {
			td {
				span.selected {
					background-color: $theme-color_1 !important;
				}
				span[class*=select-] {
					&:after {
						background-color: $theme-color_1 !important;
					}
				}
			}
			td.selected {
				span {
					background-color: $theme-color_1 !important;
				}
			}
			td[class*=select-] {
				span {
					&:after {
						background-color: $theme-color_1 !important;
					}
				}
			}
		}
	}
}
@media screen and (min-width: 280px)and (max-width: 350px) {
.otp-input {
    width: 25px !important;
    font-size: 18px !important;
    height: 25px !important;
 }
}
@media screen and  (max-width: 279px) {
  .otp-input {
      width: 20px !important;
      font-size: 16px !important;
      height: 20px !important;
   }
  }
.nav-tabs-custom {
  border-bottom: 0 !important;

  .nav-item {
    .nav-link {
      &::after {
        height: 100% !important;
        width: 3px !important;
        right: 0 !important;
        bottom: 0 !important;
        left: auto !important;
      }
    }
  }
}

.right-side-modal-class {
    position: absolute !important;
    top: 63px;
    right: 160px;
    margin: 0.5rem !important;
}

.ngx-json-viewer {
  font-family: "Poppins", sans-serif !important;
  box-sizing: border-box;
  font-size: 0.8125rem !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .segment .segment-main .segment-key {
    color: black !important;
  }
   .segment-type-string > .segment-main > .segment-value {
    color: #495057 !important;
  }
}

.ngx-slider .ngx-slider-pointer {
  background-color: #6e90f6 !important;
}
.ngx-slider .ngx-slider-bubble {
  font-size: inherit !important;
}
.ngx-slider .ngx-slider-pointer {
  width: 25px  !important;
  height: 25px !important;
  top: -10px !important;
}

.ngx-slider .ngx-slider-pointer:after {
  width: 6px !important;
  height: 6px !important;
  top: 10px !important;
  left: 10px !important;
}
